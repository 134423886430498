import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyBaI66cnwUm27iFNNMyTVU-_43QmlvPdpU",
  authDomain: "golf-app-6a9c6.firebaseapp.com",
  projectId: "golf-app-6a9c6",
  messagingSenderId: "986370808837",
  appId: "1:986370808837:web:985023208420f96651906c"
};
firebase.initializeApp(config);
export const auth = firebase.auth;
