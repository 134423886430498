import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
import PrivacyPolicy from './components/PrivacyPolicy';
import PublicRoute from './components/PublicRoute';
import { auth } from './services/firebase';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken()
        .then(token => {
          setAuthToken(token)
          this.setState({
            loading: false,
          });
          store.dispatch(setCurrentUser(user))
        }).catch(err => console.log(err.message))
      } else {
        this.setState({
          loading: false,
        });
      }
    })
  }

  render() {
    return this.state.loading ? (
      null
    ) : (
      <Provider store={store}>
        <Router>
          <div className="app">
            <Switch>
              <PublicRoute exact path="/privacy-policy" component={PrivacyPolicy} />
            </Switch>
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App;
