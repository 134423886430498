import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "./types";
import { auth } from "../services/firebase";

export const loginUser = userData => dispatch => {
  const { email, password } = userData

  // empty field error handling
  let blank = false
  let errors = {
    email: email === '' ? ['Email required.'] : [],
    password: password === '' ? ['Password required.'] : []
  }
  Object.entries(errors).forEach((item, i) => {
    if (item[1].length > 0) {
      blank = true
      dispatch({
        type: GET_ERRORS,
        payload: errors
      })
    }
  })

  // sign in flow
  if (!blank) {
    auth().signInWithEmailAndPassword(email, password)
    .catch(err => {
      let errors = {}
      if (err.code === 'auth/invalid-email') {
        errors.email = ['Please enter a valid email.']
      } else if (err.code === 'auth/wrong-password' || err.code === 'auth/user-not-found') {
        errors.general = ['Email/password combination does not exist.']
      } else {
        errors.general = ['Error logging in, please try again.']
      }
      dispatch({
        type: GET_ERRORS,
        payload: errors
      })
    })
  }
};

export const registerUser = userData => dispatch => {
  const { userName, email, password } = userData

  // empty and additional rules error handling
  let blank = false
  let errors = {
    userName: userName === '' ? ['Name required.'] : [],
    email: email === '' ? ['Email required.'] : [],
    password: password === '' ? ['Password required.'] : []
  }
  const numeric = /\d+/;
  const upperCase = /[A-Z]/;
  if (password.length < 8) {
    errors.password = ['Must be at least 8 characters.']
  } else if (!password.match(numeric)) {
    errors.password = ['Please use at least 1 number.']
  } else if (!password.match(upperCase)) {
    errors.password = ['Please use at least 1 uppercase letter.']
  }
  Object.entries(errors).forEach((item, i) => {
    if (item[1].length > 0) {
      blank = true
      dispatch({
        type: GET_ERRORS,
        payload: errors
      })
    }
  })

  // register flow that also logs in
  if (!blank) {
    auth().createUserWithEmailAndPassword(email, password)
    .then(res => {
      const user = auth().currentUser

      // update displayName in firebase
      user.updateProfile({
        displayName: userName
      })

      // send verification email
      user.sendEmailVerification()

      // add user to flask db
      axios({
        method: 'post',
        url: '/api/newuser',
        data: {
          id: user.uid
        },
        headers: {
          'content-type': 'application/json'
        }
      })
    })
    .catch(err => {
      console.log(err)
      let errors = {}
      if (err.code === 'auth/invalid-email') {
        errors.email = ['Please enter a valid email.']
      } else if (err.code === 'auth/email-already-in-use') {
        errors.general = ['Email already registered, please log in.']
      } else {
        errors.general = ['Error registering, please try again.']
      }
      dispatch({
        type: GET_ERRORS,
        payload: errors
      })
    })
  };
}

export const requestPasswordReset = (userData, onSend) => dispatch => {
  var actionCodeSettings = {
    url: 'http://localhost:3000/login',
    handleCodeInApp: false
  };

  auth().sendPasswordResetEmail(userData.email, actionCodeSettings)
  .then(res => {
    onSend()
  })
  .catch(err => {
    let errors = {}
    if (err.code === 'auth/invalid-email') {
      errors.email = ['Please enter a valid email.']
    } else if (err.code === 'auth/user-not-found') {
      errors.email = ['Email address not found, please register.']
    } else {
      errors.general = ['Error sending reset email, please try again.']
    }
    dispatch({
      type: GET_ERRORS,
      payload: errors
    })
  })
};

export const clearErrors = () => {
  return {
    type: GET_ERRORS,
    payload: {}
  }
}

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

export const logoutUser = () => dispatch => {
  setAuthToken(false);
  auth().signOut()
  dispatch(setCurrentUser({}));
};
