import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <h1>
          Privacy Policy
        </h1>
        <p>
          MC Technologies, LLC (“MCT”, “we”, or “our”) value your privacy and are committed to protecting your personal information. We are providing this Privacy Policy (“Policy”) to inform you of our policies and procedures regarding the collection, use, and disclosure of information related to our Stroke Shaver Application (the “Application”). By using the Application, you agree that your personal information will be handled as described in this Policy. Note that this Policy may be modified from tie to time in our sole discretion.
        </p>
        <h2>
          Information we Collect
        </h2>
        <p>
          We collect the following kinds of information:
        </p>
        <ul>
          <li>
            Account Information: When you register with us through the Application to become a registered User, we will ask you for certain information about you that can be used to contact or identify you (“Personally Identifiable Information” or “PII”). PII includes:
            <ul>
              <li>
                Your user name, which can be any name you choose, is only used to personalize content within the Application.
              </li>
              <li>
                Your email address and password are used to login to the Application and your email will also be used for password resets.
              </li>
              <li>
                Your gender is used to determine which pars and handicaps to display during your rounds.
              </li>
            </ul>
          </li>
          <li>
            Alerts and Notifications: We rely on customer input to add new courses to our database. If you allow notifications, then we will send you a notification when we have successfully uploaded a course you have submitted.
          </li>
          <li>
            Third-Parties: We rely on two third-party services that provide core functionality for the Application:
            <ul>
              <li>
                Expo: We use Expo to build the front end application that the User interacts with. They collect very limited customer information that is only tied to core functionality. For a description of what information they collect and why, you can visit their <a href='https://expo.io/privacy'>Privacy Policy</a>.
              </li>
              <li>
                Google Firebase: We value your privacy, and have therefore entrusted the authentication for the Application to Google Firebase. We store all PII with them to help ensure its security.
              </li>
            </ul>
          </li>
        </ul>
        <h2>
          How we Use  and Share Your Information:
        </h2>
        <p>
          Your information is solely used to enhance your experience with the Application. We do not use any of your information for advertising, and will never share it with Third-Party Services, other than the two listed above.
        </p>
        <p>
          Law and Harm: Notwithstanding anything to the contrary in this Policy, we may preserve or disclose your information, including your PII, if we believe that it is reasonably necessary to comply with a law, regulation or legal request; to protect the safety of any person; to address fraud, security, or technical issues; or to protect our rights or property. However, nothing in this Policy is intended to limit any legal defenses or objections that you may have to a third party’s, including a government’s, request to disclose your information. We may access, preserve and share your information in response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so. This may include responding to legal requests from jurisdictions outside of the United States where we have a good faith belief that the response is required by law in that jurisdiction, affects users in that jurisdiction, and is consistent with internationally recognized standards. We may also access, preserve and share information when we have a good faith belief it is necessary to: detect, prevent and address fraud and other illegal activity; to protect ourselves, you and others, including as part of investigations; and to prevent death or imminent bodily harm. Information we receive about you may be accessed, processed and retained for an extended period of time when it is the subject of a legal request or obligation, governmental investigation, or investigations concerning possible violations of our terms or policies, or otherwise to prevent harm.
        </p>
        <h2>
          Modifying your Personal Information:
        </h2>
        <p>
          If you are a Registered User of the Application, we provide you with the tools and account setting to access or modify your account information.
        </p>
        <p>
          You can permanently delete your account by contacting us at <a href="mailto: support@stroke-shaver.com">support@stroke-shaver.com</a>.
        </p>
        <h2>
          The Security of Your Information:
        </h2>
        <p>
          We take commercially reasonable measures to protect the information that we collect from or about you (including PII) from unauthorized access, use or disclosure. However, no method of digital transmission, or method of digital storage, is 100% secure. Accordingly, we cannot ensure the security of any information you transmit through the Application or guarantee that information on the Application may not be inappropriately accessed, disclosed, altered, or destroyed.
        </p>
        <h2>
          Our Policy Towards Children:
        </h2>
        <p>
          Our Service is not intended for persons under 13 years of age. If you become aware that your child has provided us with PII without your consent, please contact us. We do not knowingly collect PII from persons under 13 years of age. If we become aware that a person under 13 years of age has provided us with PII, we take steps to remove such information and terminate the person’s account.
        </p>
        <h2>
          Changes to this Privacy Policy:
        </h2>
        <p>
          Any information that is collected via the Application is covered by the Privacy Policy in effect at the time such information is collected. We may revise this Privacy Policy from time to time. If we make changes to this Privacy Policy, we’ll notify you of those changes by posting them on the Service or by sending you an email or other notification. We encourage you to review the Privacy Policy whenever you access the Application to stay informed about our information practices and the ways you can help protect your privacy. By continuing to access or use the Application after those changes become effective, you agree to be bound by the revised Privacy Policy.  If you have any questions regarding this Privacy Policy, please contact us at <a href="mailto: support@stroke-shaver.com">support@stroke-shaver.com</a>.
        </p>
        <h2>
          Definitions:
        </h2>
        <p>
          Registered User: an individual that registers for the Application with his/her email and password.
        </p>
      </div>
    )
  }
}
